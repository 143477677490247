export enum ACTIONS {
  LOAD_COUNTRIES = "LOAD_COUNTRIES",
  LOAD_PROFILE = "LOAD_PROFILE",
  LOAD_RESTRICTIONS = "LOAD_RESTRICTIONS",
  CHECK_FILTERS = "CHECK_FILTERS",
  SEARCH = "SEARCH",
  RESET_SANCTIONS_DB_WITH_FILE = "RESET_SANCTIONS_DB_WITH_FILE",
  AUTH = "AUTH",
  REGISTER = "REGISTER",
  VERIFY = "VERIFY",
  SUBMIT_CONTACT_FORM = "SUBMIT_CONTACT_FORM",
  RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_CONFIRM = "RECOVER_PASSWORD_CONFIRM",
  REGISTRATION_CONFIRM = "REGISTRATION_CONFIRM",
  RETRY_EMAIL_CONFIRM = "RETRY_EMAIL_CONFIRM",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_PROFILE = "CHANGE_PROFILE",
  BILLING_CREATE_PAYMENT = "BILLING_CREATE_PAYMENT",
  BILLING_ADD_REQUESTS_PAYMENT = "BILLING_ADD_REQUESTS_PAYMENT",
  BILLING_TARRIF_UPDATED = "BILLING_TARRIF_UPDATED",
  BILLING_TARRIF_USER_NOTICED = "BILLING_TARRIF_USER_NOTICED",
  SAVE_TARRIFS_SETTINGS = "SAVE_TARRIFS_SETTINGS",
  LOAD_TARRIFS_SETTINGS = "LOAD_TARRIFS_SETTINGS",
  FORCE_LOGOUT = "FORCE_LOGOUT",
  INACTIVITY_LOGOUT = "INACTIVITY_LOGOUT",
  LAST_ACTIVITY_TIME = "LAST_ACTIVITY_TIME",
  LOGOUT = "LOGOUT",
}
